import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'

const baseURL = "/options";

export default ({
    namespaced: true,
    state : {
        all_roles:[],
        all_districts:[],
        all_product_types:[],
        all_product_categories:[],
        all_retail_territory_managers: [],
        all_retail_district_managers: [],
        all_retailers:[],
        all_zones:[],
        all_clusters:[],
        all_products:[],
        all_sub_clusters:[],
        all_plants:[],
        all_tanks:[],
        all_pumps:[],
        all_retailer_sites:[],
        all_variances:[],
        all_permissions: [],
        all_adjustment_categories: [],
        all_sites:[],
        all_site_products:[],
        all_modules:[],
        all_retailer_owners:[]
    },
    actions : {
        async getAllRoles({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/roles`, {params: {...payload}});
            commit("SET_ALL_ROLES", data);
        },
        async getAllDistricts({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/districts`, {params: {...payload}});
            commit("SET_ALL_DISTRICTS", data);
        },
        async getAllProductTypes({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/product_types`, {params: {...payload}});
            commit("SET_ALL_PRODUCT_TYPES", data);
        },
        async getAllProductCategories({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/product_categories`, {params: {...payload}});
            commit("SET_ALL_PRODUCT_CATEGORIES", data);
        },
        async getAllRetailTerritoryManagers({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/retail_territory_managers`, {params: {...payload}});
            commit("SET_ALL_RETAIL_TERRITORY_MANAGERS", data);
        },
        async getAllRetailDistrictManagers({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/retail_district_managers`, {params: {...payload}});
            commit("SET_ALL_RETAIL_DISTRICT_MANAGERS", data);
        },
        async getAllRetailers({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/retailers`, {params: {...payload}});
            commit("SET_ALL_RETAILERS", data);
        },
        async getAllZones({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/zones`, {params: {...payload}});
            commit("SET_ALL_ZONES", data);
        },
        async getAllClusters({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/clusters`, {params: {...payload}});
            commit("SET_ALL_CLUSTERS", data);
        },
        async getAllSubClusters({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/sub_clusters`, {params: {...payload}});
            commit("SET_ALL_SUB_CLUSTERS", data);
        },
        async getAllProducts({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/products`, {params: {...payload}});
            commit("SET_ALL_PRODUCTS", data);
        },
        async getAllPlants({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/plants`, {params: {...payload}});
            commit("SET_ALL_PLANTS", data);
        },
        async getAllTanks({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/tanks`, {params: {...payload}});
            commit("SET_ALL_TANKS", data);
        },
        async getAllPumps({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/pumps`, {params: {...payload}});
            commit("SET_ALL_PUMPS", data);
        },
        async getAllRetailerSites({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/retailer_sites`, {params: {...payload}});
            commit("SET_ALL_RETAILER_SITES", data);
        },
        async getAllSitesPerRetailer({commit}: {commit: Commit}, {selected_retailer="",payload={}}) {
            const { data } = await axios.get(`${baseURL}/retailer_sites`, {params: {...payload,selected_retailer: selected_retailer}});
            commit("SET_ALL_RETAILER_SITES", data);
        },
        async getAllVariances({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/variances`, {params: {...payload}});
            commit("SET_ALL_VARIANCES", data);
        },
        async getAllPermissions({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/permissions`, {params: {...payload}});
            commit("SET_ALL_PERMISSIONS", data);
        },
        async getAllAdjustmentCategories({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/adjustment_categories`, {params: {...payload}});
            commit("SET_ALL_ADJUSTMENT_CATEGORIES", data);
        },
        async getAllSites({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/sites`, {params: {...payload}});
            commit("SET_ALL_SITES", data);
        },
        async getAllSiteProduct({commit}: {commit: Commit}, {selected_site_id="",payload={}}) {
            const { data } = await axios.get(`${baseURL}/site_products`, {params: {...payload,selected_site_id: selected_site_id}});
            commit("SET_ALL_SITE_PRODUCTS", data);
        },
        async getAllModules({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/modules`, {params: {...payload}});
            commit("SET_ALL_MODULES", data);
        },
        async getAllRetailerOwners({commit}: {commit: Commit}, payload={}) {
            const { data } = await axios.get(`${baseURL}/retailer_owners`, {params: {...payload}});
            commit("SET_ALL_RETAILER_OWNERS", data);
        },
    },
    mutations:{
        SET_ALL_ROLES(state:any, data: any) {
            state.all_roles = data;
        },
        SET_ALL_DISTRICTS(state:any, data: any) {
            state.all_districts = data;
        },
        SET_ALL_PRODUCT_TYPES(state:any, data: any) {
            state.all_product_types = data;
        },
        SET_ALL_PRODUCT_CATEGORIES(state:any, data: any) {
            state.all_product_categories = data;
        },
        SET_ALL_RETAIL_TERRITORY_MANAGERS(state:any, data: any) {
            state.all_retail_territory_managers = data;
        },
        SET_ALL_RETAIL_DISTRICT_MANAGERS(state:any, data: any) {
            state.all_retail_district_managers = data;
        },
        SET_ALL_RETAILERS(state:any, data: any) {
            state.all_retailers = data;
        },
        SET_ALL_ZONES(state:any, data: any) {
            state.all_zones = data;
        },
        SET_ALL_CLUSTERS(state:any, data: any) {
            state.all_clusters = data;
        },
        SET_ALL_PRODUCTS(state:any, data: any) {
            state.all_products = data;
        },
        SET_ALL_SUB_CLUSTERS(state:any, data: any) {
            state.all_sub_clusters = data;
        },
        SET_ALL_PLANTS(state:any, data: any) {
            state.all_plants = data;
        },
        SET_ALL_TANKS(state:any, data: any) {
            state.all_tanks = data;
        },
        SET_ALL_PUMPS(state:any, data: any) {
            state.all_pumps = data;
        },
        SET_ALL_RETAILER_SITES(state:any, data: any) {
            state.all_retailer_sites = data;
        },
        SET_ALL_VARIANCES(state:any, data: any) {
            state.all_variances = data;
        },
        SET_ALL_PERMISSIONS(state:any, data: any) {
            state.all_permissions = data;
        },
        SET_ALL_ADJUSTMENT_CATEGORIES(state:any, data: any) {
            state.all_adjustment_categories = data;
        },
        SET_ALL_SITES(state:any, data: any) {
            state.all_sites = data;
        },
        SET_ALL_SITE_PRODUCTS(state:any, data: any) {
            state.all_site_products = data;
        },
        SET_ALL_MODULES(state:any, data: any) {
            state.all_modules = data;
        },
        SET_ALL_RETAILER_OWNERS(state:any, data: any) {
            state.all_retailer_owners = data;
        },
    },
    getters:{
        all_roles(state: any){
            return state.all_roles;
        },
        all_districts(state: any){
            return state.all_districts;
        },
        all_product_types(state: any){
            return state.all_product_types;
        },
        all_product_categories(state: any){
            return state.all_product_categories;
        },
        all_retail_territory_managers(state: any){
            return state.all_retail_territory_managers;
        },
        all_retail_district_managers(state: any){
            return state.all_retail_district_managers;
        },
        all_retailers(state: any){
            return state.all_retailers;
        },
        all_zones(state: any){
            return state.all_zones;
        },
        all_clusters(state: any){
            return state.all_clusters;
        },
        all_products(state: any){
            return state.all_products;
        },
        all_plants(state: any){
            return state.all_plants;
        },
        all_sub_clusters(state: any){
            return state.all_sub_clusters;
        },
        all_tanks(state: any){
            return state.all_tanks;
        },
        all_pumps(state: any){
            return state.all_pumps;
        },
        all_retailer_sites(state: any){
            return state.all_retailer_sites;
        },
        all_variances(state: any){
            return state.all_variances;
        },
        all_permissions(state: any){
            return state.all_permissions;
        },
        all_adjustment_categories(state: any){
            return state.all_adjustment_categories;
        },
        all_sites(state: any){
            return state.all_sites;
        },
        all_site_products(state: any){
            return state.all_site_products;
        },
        all_retailer_owners(state: any){
            return state.all_retailer_owners;
        },
        all_modules(state: any){
            return state.all_modules;
        },
    }
});




