import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'

const baseURL = "/announcements";

export default ({
    namespaced: true,
    state : {
        clusters: [],
        item:{},
        status: 'loading'
    },
    actions : {
        async store({dispatch}: {dispatch: Dispatch}, payload={}){
            const { data } = await axios.post(`${baseURL}`, payload)
            return data
        }

    },
    mutations:{
        SET_ITEMS(state:any, data: any){
            state.clusters = data.data;
        },
        SET_STATUS(state: any, payload: string){
            state.status = payload;
        },
    },
    getters:{
        items(state: any){
            return state.clusters;
        },
        status(state: any){
            return state.status;
        }
    }
});




