import axios from "axios";
import router from './router'
// import store from "./index";
import { Modal } from 'ant-design-vue';
import {  h } from 'vue';
import Swal from 'sweetalert2'
// import { type } from "jquery";

const baseURL = 'https://smp-dwsr.com/backend/api/v1/';
const appURL = 'https://smp-dwsr.com/';
const VUE_APP_DWSR_ADMIN_EMAIL = 'shelldwsr@gmail.com'

// const api = process.env.VUE_APP_BASEURL;
const axiosRequest = axios.create({
    baseURL: baseURL,
    timeout: 20000,
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem("DWSR_TOKEN")}`,
        // account: window.localStorage.getItem("selectedAccount"),
        // accountIndex: window.localStorage.getItem("accountIndex")
    }
});

// RESPONSE
// Add a response interceptor
axiosRequest.interceptors.response.use(
    function(response) {
        // Do something with response data
        // console.log(response);
        if (response.status == 201) {
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonText: 'OK',
              })
            // Modal.success({
            //     title: 'Success',
            //     content: h('div', {}, [
            //         h('p', response.data.message)
            //     ]),
            //     onOk() {
            //         Modal.destroyAll();
            //     },
            // });
        }else if(response.status == 202){
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonText: 'OK',
              })
        }else if(response.status == 204){
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonText: 'OK',
              })
        }
        return response;
    },
    function(error) {
        // Do something with response error

        if (error.response.status == 401) {
            // store.commit("SET_ERROR", 401);
            // router.push('login')
            Swal.fire({
                title: 'Permission Denied',
                text: "You don't have permission to perform such operation.",
                icon: 'error',
                confirmButtonText: 'OK',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    router.push('/login')
                } 
              })
            // Modal.error({
            //     title: 'Permission Denied',
            //     content: "You don't have permission to perform such operation.",
            // });
        }
        if (error.response.status == 422) {
            const item = error.response.data.errors;
            let errors = "";
            for (const key in item) {
                errors += `${item[key]}<br>`;
                //console.log(`${key}: ${item[key]}`);
            }

            //const errors = Object.keys(error.response.data.errors);

            //console.log(errors);
            Swal.fire({
                title: error.response.data.message,
                icon: "error",
                html: errors,
                confirmButtonText: "OK",
            });
            // Modal.error({
            //     title: 'Error',
            //     content: temp,
            // });
        }

        if (error.response.status == 500) {
            Swal.fire({
                title: 'Server Error',
                text: "Internal server error occurred. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }

        if(error.response.status == undefined){
            router.push('login')
        }


        return Promise.reject(error);
    }
);

export default axiosRequest;
export {
    baseURL,
    appURL,
    VUE_APP_DWSR_ADMIN_EMAIL
}
function elseif() {
    throw new Error("Function not implemented.");
}

