import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Antd from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';
import Card from './components/Card.vue'
import DataTable from './components/DataTable.vue'
import Search from './components/Search.vue'
import {DashboardOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    FlagOutlined,
    UserOutlined,
    LockOutlined,
    PlusOutlined,
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
    SettingOutlined,
    ShopOutlined,
    FireOutlined,
    CloseCircleOutlined,
    HomeOutlined,
    AppstoreOutlined,
    ToolOutlined,
    MenuOutlined
} from '@ant-design/icons-vue'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(Antd)
app.component('Card',Card)
app.component('DataTable',DataTable)
app.component('Search',Search)
app.component('DashboardOutlined', DashboardOutlined)
app.component('MenuUnfoldOutlined', MenuUnfoldOutlined)
app.component('MenuFoldOutlined', MenuFoldOutlined)
app.component('FlagOutlined', FlagOutlined)
app.component('UserOutlined', UserOutlined)
app.component('LockOutlined', LockOutlined)
app.component('PlusOutlined', PlusOutlined)
app.component('EyeOutlined', EyeOutlined)
app.component('EditOutlined', EditOutlined)
app.component('SettingOutlined', SettingOutlined)
app.component('ShopOutlined', ShopOutlined)
app.component('FireOutlined', FireOutlined)
app.component('CloseCircleOutlined', CloseCircleOutlined)
app.component('HomeOutlined', HomeOutlined)
app.component('AppstoreOutlined', AppstoreOutlined)
app.component('ToolOutlined', ToolOutlined)
app.component('MenuOutlined', MenuOutlined)
app.component('DeleteOutlined', DeleteOutlined)
app.mount('#app')