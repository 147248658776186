import { Entity } from '@/interfaces/entity';
import { Role } from './role';

export class User implements Entity{
    id: string;
    email: string;
    last_name: string;
    first_name: string;
    role: Role;
    sidebar: any;

    constructor(id='', email='',last_name = "", first_name = "",  role= new Role(),sidebar=[]){
        this.id = id;
        this.email = email;
        this.last_name = last_name;
        this.first_name = first_name;
        this.role = role;
        this.sidebar = sidebar;
    }

    // hasPermssion(route: string){
    //     return this.permissions.some(p => p === 'route');
    // }
    get name(){
        return this.first_name + ' ' + this.last_name
    }

    isAdmin(){
        return this.role.view == 'Admin';
    }
}