import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'

const baseURL = "/retailer_sites";

export default ({
    namespaced: true,
    state : {
        sites: [],
        item:{},
        status: 'loading'
    },
    actions : {
        async index({commit}: {commit: Commit}, payload={}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, {params: {...payload}});
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },
        
        async store({dispatch}: {dispatch: Dispatch}, payload={}){
            const { data } = await axios.post(`${baseURL}`, payload);
            dispatch('index')
            return data
        },

        async update({dispatch}: {dispatch: Dispatch}, payload={id: ''}){
            const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
            dispatch('index')
            return data
        },

        async destroy({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.delete(`${baseURL}/${id}`);
            dispatch('index')
            return data
        },

        async show({commit}: {commit: Commit}, id=""){
            const { data } = await axios.get(`${baseURL}/${id}`);
            return data
        },

    },
    mutations:{
        SET_ITEMS(state:any, data: any){
            state.sites = data.data;
        },
        SET_STATUS(state: any, payload: string){
            state.status = payload;
        },
    },
    getters:{
        items(state: any){
            return state.sites;
        },
        status(state: any){
            return state.status;
        }
    }
});




