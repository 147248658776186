import { Commit, Dispatch } from 'vuex'
import axios from "../../axios";

const baseURL = "/inventory_adjustments";

export default ({
    namespaced: true,
    state : {
        inventory_adjustments: [],
        item:{},
        status: 'loading'
    },
    actions: {
        async index({commit}: {commit: Commit}, payload={}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, {params: {...payload}});
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },
        async store({dispatch}: {dispatch: Dispatch}, payload={}){
            const { data } = await axios.post(`${baseURL}`, payload);
            return data
        },
        async update({dispatch}: {dispatch: Dispatch}, payload={id: ''}){
            const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
            return data
        },
        async destroy({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.delete(`${baseURL}/${id}`);
            return data
        }
    },

    mutations: {
        SET_ITEMS(state:any, data: any){
            state.inventory_adjustments = data.data;
        },
        SET_STATUS(state: any, payload: string){
            state.status = payload;
        },
    },

    getters: {
        items(state: any){
            return state.inventory_adjustments;
        },
        status(state: any){
            return state.status;
        }
    }
})