import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login.vue')
  },
  {
    path: '/account-recovery',
    name: 'AccountRecovery',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/AccountRecovery.vue')
  },
  {
    path: '/email-sent?email=:email',
    name: 'EmailSent',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/EmailSent.vue'),
    props: true
  },
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: () => import('../components/BaseContainer.vue'),
    children:[
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/dashboard/Dashboard.vue')
      },

      //site management
      {
        path: '/zone',
        name: 'Zone',
        component: () => import('../views/admin/site_management/zone/Zone.vue')
      },
      {
        path: '/district',
        name: 'District',
        component: () => import('../views/admin/site_management/district/District.vue')
      },
      {
        path: '/admin-site-details',
        name: 'Admin Site Details',
        component: () => import('../views/admin/site_management/site_details/SiteDetails.vue')
      },
      {
        path: '/site-details/create',
        name: 'site-details-create',
        component: () => import('../views/admin/site_management/site_details/Form.vue')
      },
      {
        path: '/site-details/edit/:id',
        name: 'site-details-edit',
        component: () => import('../views/admin/site_management/site_details/Form.vue'),
        props: true
      },
      {
        path: '/clusters',
        name: 'Cluster',
        component: () => import('../views/admin/site_management/cluster/Cluster.vue')
      },
      {
        path: '/sub-clusters',
        name: 'SubCluster',
        component: () => import('../views/admin/site_management/sub_cluster/SubCluster.vue')
      },
      {
        path: '/plants',
        name: 'Plant',
        component: () => import('../views/admin/site_management/plant/Plant.vue')
      },
      {
        path: '/admin-retailer',
        name: 'Retailers',
        component: () => import('../views/admin/site_management/retailer/Retailer.vue')
      },


      //maintenance
      {
        path: '/roles',
        name: 'Roles',
        component: () => import('../views/admin/admin_settings/role/Role.vue')
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/admin/admin_settings/user/User.vue')
      },
      {
        path: '/pumps',
        name: 'Pump',
        component: () => import('../views/admin/admin_settings/pump/Pump.vue')
      },
      {
        path: '/tanks',
        name: 'Tank',
        component: () => import('../views/admin/admin_settings/tank/Tank.vue')
      },
      {
        path: '/audit-trail-logins',
        name: 'Audit Trail Logins',
        component: () => import('@/views/admin/admin_settings/audit/LoginAuditTrail.vue')
      },
      {
        path: '/exception-logs',
        name: 'Exception Logs',
        component: () => import('@/views/admin/admin_settings/exception_log/ExceptionLog.vue')
      },
      {
        path: '/tax-types',
        name: 'Tax Type',
        component: () => import('@/views/admin/admin_settings/tax_type/TaxType.vue')
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue')
      },
      {
        path: '/variance',
        name: 'variance',
        component: () => import('@/views/admin/admin_settings/variance/Variance.vue')
      },
      {
        path: '/permission',
        name: 'permission',
        component: () => import('@/views/admin/admin_settings/permission/Permission.vue')
      },
      {
        path: '/announcements',
        name: 'announcements',
        component: () => import('@/views/admin/admin_settings/announcement/Announcement.vue')
      },
      {
        path: '/inventory-logs',
        name: 'Inventory Logs',
        component: () => import('@/views/admin/admin_settings/inventory_logs/InventoryLogs.vue')
      },
    

      //product management
      {
        path: '/product-type',
        name: 'Product-Type',
        component: () => import('../views/admin/admin_settings/product_management/product_type/ProductType.vue')
      },
      {
        path: '/product-category',
        name: 'Product-Category',
        component: () => import('../views/admin/admin_settings/product_management/product_category/ProductCategory.vue')
      },
      {
        path: '/products',
        name: 'product',
        component: () => import('../views/admin/admin_settings/product_management/product/Product.vue')
      },
      {
        path: '/product/create',
        name: 'product-form-create',
        component: () => import('../views/admin/admin_settings/product_management/product/Form.vue')
      },
      {
        path: '/product/edit/:id',
        name: 'product-form-edit',
        component: () => import('../views/admin/admin_settings/product_management/product/Form.vue'),
        props: true
      },
      {
        path: '/adjustment-category',
        name: 'Adjustment Category Index',
        component: () => import('@/views/admin/admin_settings/adjustment_category/AdjustmentCategory.vue')
      },

      //inventory
      {
        path: '/admin-inventory',
        name: 'Admin Inventory',
        component: () => import('../views/admin/inventory_management/inventory/Inventory.vue')
      },


      //reports
      {
        path: '/plip-report',
        name: 'plip-report',
        component: () => import('../views/admin/reports/plip_report/PlipReport.vue')
      },
      {
        path: '/variance-report',
        name: 'variance-report',
        component: () => import('../views/admin/reports/variance_report/Variance.vue')
      },
    ]
  },

  //retailers
  {
    path: '/retailer',
    redirect: '/retailer',
    name: 'Retailer Page',
    component: () => import('../components/BaseContainerRetailer.vue'),
    children:[
      {
        path: '/retailer',
        name: 'Retailer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/retailer/RetailerDashboard.vue')
      },
      {
        path: '/retailer-site-details',
        name: 'retailer-site-details-create',
        component: () => import('../views/retailer/site_management/site_details/SiteDetails.vue')
      },
      {
        path: '/retailer-site-details-form/:id',
        name: 'retailer-site-details-edit',
        component: () => import('../views/retailer/site_management/site_details/SiteDetailsForm.vue'),
        props: true
      },

      //inventory
      {
        path: '/inventory',
        name: 'Retailer Inventory',
        component: () => import('../views/retailer/inventory_management/inventory/Inventory.vue')
      },

      //sales
      {
        path: '/retailer-sales',
        name: 'Retailer Sales',
        component: () => import('../views/retailer/sales_management/sales/Sales.vue')
      },
      {
        path: '/sales/create',
        name: 'Retailer Sales Form',
        component: () => import('../views/retailer/sales_management/sales/Form.vue')
      },

      //reports
      {
        path: '/retailer-plip-report',
        name: 'retailer-plip-report',
        component: () => import('../views/retailer/reports/plip_report/PlipReport.vue')
      },
    ]
  }
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'AccountRecovery' && to.name !== 'EmailSent' && !window.localStorage.getItem("DWSR_TOKEN")) next({ name: 'Login' })
  else next()
})


export default router
