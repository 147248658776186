import axios from '../../axios'
import { Commit } from 'vuex'

const baseURL = '/dashboard'

export default ({
  namespaced: true,
  state: {
    all_posted_inventory: [],
    all_product_status: [],
  },
  actions: {
    async getAllPostedInventory ({commit}: { commit: Commit }, {
      site_id = '',
      month = '',
      year = '',
      payload = {}
    }) {
      const {data} = await axios.get(`${baseURL}/calendar`, {
        params: {
          ...payload,
          site_id: site_id,
          month: month,
          year: year
        }
      })
      commit('SET_ALL_POSTED_INVENTORY', data)
    },
    async getProductStatus ({commit}: { commit: Commit }, {
      site_id = '',
      date = '',
      payload = {}
    }) {
      const {data} = await axios.get(`${baseURL}/product/status`, {
        params: {
          ...payload,
          site_id: site_id,
          date: date,
        }
      })
      commit('SET_ALL_PRODUCT_STATUS', data)
    },
  },
  mutations: {
    SET_ALL_POSTED_INVENTORY (state: any, data: any) {
      state.all_posted_inventory = data.data
    },
    SET_ALL_PRODUCT_STATUS (state: any, data: any) {
      state.all_product_status = data.data
    },
  },
  getters: {
    all_posted_inventory (state: any) {
      return state.all_posted_inventory
    },
    all_product_status (state: any) {
      return state.all_product_status
    },
  }
})




