import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'

const baseURL = "/inventories";

export default ({
    namespaced: true,
    state : {
        inventories: [],
        item:{},
        status: 'loading',
        history_items:{},
        histories: [],
    },
    actions : {
        async index({commit}: {commit: Commit}, {selected_site="",payload={}}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, {params: {...payload,site: selected_site}});
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },
        
        async store({dispatch}: {dispatch: Dispatch}, payload={}){
            const { data } = await axios.post(`${baseURL}`, payload);
     
            return data
        },

        async update({dispatch}: {dispatch: Dispatch}, payload={id: ''}){
            // const selected_site = payload.site_id;
            const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
            // dispatch('index', {site: selected_site})
            return data
        },

        async destroy({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.delete(`${baseURL}/${id}`);
            dispatch('index')
            return data
        },

        async show({commit}: {commit: Commit}, id=""){
            const { data } = await axios.get(`${baseURL}/${id}`);
            return data
        },

        async history({commit}: {commit: Commit}, {selected_month="",record={site_product_id: '',site_id: ''}, payload = {}}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}/${selected_month}/history/${record.site_product_id}/site/${record.site_id}`, {params: {...payload}});
            commit('SET_HISTORY_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

    },
    mutations:{
        SET_ITEMS(state:any, data: any){
            state.inventories = data.data;
        },
        SET_STATUS(state: any, payload: string){
            state.status = payload;
        },
        SET_HISTORY_ITEMS(state:any, data: any){
            state.histories = data.data;
        },
    },
    getters:{
        items(state: any){
            return state.inventories;
        },
        status(state: any){
            return state.status;
        },
        history_items(state: any){
            return state.histories;
        },
    }
});




