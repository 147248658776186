import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'

const baseURL = "/retailer_inventories";

export default ({
    namespaced: true,
    state : {
        retailer_inventories: [],
        histories: [],
        item:{},
        history_items:{},
        status: 'loading'
    },
    actions : {
        async index({commit}: {commit: Commit}, {selected_site="",payload={page: 1}}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}`, {params: {...payload,site: selected_site}});
            //  const { data } = await axios.get(`${baseURL}`, {params: {...payload}});
            commit('SET_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },
        
        async store({dispatch}: {dispatch: Dispatch}, payload={}){
            const { data } = await axios.post(`${baseURL}`, payload);
           
            return data
        },

        async update({dispatch}: {dispatch: Dispatch}, payload={id: ''}){
            const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
           
            return data
        },

        async destroy({dispatch}: {dispatch: Dispatch}, id=""){
            const { data } = await axios.delete(`${baseURL}/${id}`);
            dispatch('index')
            return data
        },

        async show({commit}: {commit: Commit}, id=""){
            const { data } = await axios.get(`${baseURL}/${id}`);
            return data
        },

        async history({commit}: {commit: Commit}, {selected_month="",record={site_product_id: '',site_id: ''}}){
            commit('SET_STATUS', 'loading')
            const { data } = await axios.get(`${baseURL}/${selected_month}/history/${record.site_product_id}/site/${record.site_id}`);
            commit('SET_HISTORY_ITEMS', data)
            commit('SET_STATUS', 'completed')
            return data
        },

    },
    mutations:{
        SET_ITEMS(state:any, data: any){
            state.retailer_inventories = data.data;
        },
        SET_HISTORY_ITEMS(state:any, data: any){
            state.histories = data.data;
        },
        SET_STATUS(state: any, payload: string){
            state.status = payload;
        },
    },
    getters:{
        items(state: any){
            return state.retailer_inventories;
        },
        history_items(state: any){
            return state.histories;
        },
        status(state: any){
            return state.status;
        }
    }
});




