<template>
  <a-row style="margin-bottom: 15px;">
    <a-col class="search" span="24">
      <a-input-search
        placeholder="search"
        style="width: 300px"
        v-model:value="form.search"
        @input="
          debounce(() => {
            form.search = $event.target.value;
          })
        "
      />
      <a-button type="primary" @click="$emit('create')" v-if="showCreateButton">
        <template #icon><PlusOutlined /></template> {{ addText }}
      </a-button>
    </a-col>
  </a-row>

  <a-table
    bordered
    size="small"
    row-key="id"
    :pagination="false"
    :loading="loading"
    :columns="columns"
    :data-source="data"
    style="overflow-x: auto;overflow-y: auto;"
  >
    <template v-slot:action="{ record }">
      <a-space>
        <a-button type="primary" @click="$emit('edit', record)" size="small" v-if="showEditButton">
          Edit
        </a-button>
        <a-popconfirm
          title="Sure to delete?"
          @confirm="$emit('destroy', record)"
        >
          <a-button type="danger" size="small" v-if="showDeleteButton"> Delete</a-button>
        </a-popconfirm>
      </a-space>
    </template>
    <template v-slot:inventory="{ record }">
      <a-space>
        <a-button type="primary" @click="$emit('remark', record)" size="small" v-if="showRemarksButton">
          Remarks
        </a-button>
        <a-button type="primary" @click="$emit('adjustment', record)" size="small" v-if="showAdjustmentsButton">
          Adjustment
        </a-button>
        <a-button type="primary" @click="$emit('encode', record)" size="small" v-if="showEncodeButton && record.status != 1">
          Encode
        </a-button>
         <a-button type="primary" @click="$emit('post', record)" size="small" v-if="showPostButton && record.status != 1">
          Post
        </a-button>
        <a-button type="primary" @click="$emit('history', record)" size="small" v-if="showHistoryButton">
          History
        </a-button>
      </a-space>
    </template>
    <template v-slot:plip_report="{ record }">
      <a-space>
        <a-button type="primary" @click="$emit('download', record)" size="small" >
          Download
        </a-button>
        <a-button type="primary" @click="$emit('edit', record)" size="small" >
          Edit
        </a-button>
        <a-popconfirm
          title="Sure to delete?"
          @confirm="$emit('destroy', record)"
        >
          <a-button type="danger" size="small" v-if="showDeleteButton"> Delete</a-button>
        </a-popconfirm>
      </a-space>
    </template>
    <template v-slot:is_active="{ record }">
      <a-checkbox :checked="record.is_active" readonly />
    </template>
    <template v-slot:positive="{ record }">
      X ≥ {{record.threshold_value}}
    </template>
    <template v-slot:marginal="{ record }">
      {{record.threshold_value}} > X ≥  0
    </template>
    <template v-slot:negative>
      X &lt; 0
    </template>
    <template v-slot:for_coastal_doi="{ record }">
      X ≥ {{record.volume_threshold}}
    </template>
    <template v-slot:for_selective_priceoff="{ record }">
      X ≥ {{record.volume_threshold}}
    </template>
    <template v-slot:site_index_retailer_users="{ record }">
      <a-tag v-for="retailer in record.retailers" :key="retailer.id">
        {{ retailer.retailer_user.full_name }}
      </a-tag>
    </template>
    <template v-slot:audit_logs_payload="{ record }">
      <ul>
        <template v-for="(value, name) in JSON.parse(record.payload)" :key="value">
          <li v-if="value !== '' && value !== null">
            <div>{{ name }} - {{ value }}</div>
          </li>
        </template>
      </ul>
    </template>

  </a-table>

  <a-row style="margin: 15px 15px;" v-if="data.length">
    <a-col class="search" span="24">
      <div>
        <span
          >{{ pagination.from }} - {{ pagination.to }} of
          {{ pagination.total }}</span
        >
      </div>
      <div>
        <a-pagination size="small" :total="pagination.total" show-size-changer show-quick-jumper @change="onChange"
                      @showSizeChange="onShowLimit" :current="form.page"/>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    addText: {
      type: String,
      default: "Create",
    },
    showCreateButton: {
      type: Boolean,
      default: true
    },
    showEditButton: {
      type: Boolean,
      default: true
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    },
    showEncodeButton: {
      type: Boolean,
      default: false
    },
    showPostButton:{
      type: Boolean,
      default: false
    },
    showHistoryButton:{
      type: Boolean,
      default: false
    },
    showRemarksButton:{
      type: Boolean,
      default: false
    },
    showAdjustmentsButton:{
      type: Boolean,
      default: false
    },
    pagination: {
      type: Object,
    },
  },
  emits: ["edit", "destroy", "create", "filter"],
  setup(props, { emit }) {
    const form = reactive({
      page: 1,
      limit: 10,
      search: "",
    });

    const filter = () => {
      emit("filter", form);
    };

    function createDebounce() {
      let timeout = null;
      return function(fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          const { page } = toRefs(form);
          page.value = 1;
          emit("filter", form);
        }, delayMs || 500);
      };
    }

    const onChange = (payload) => {
      const { page } = toRefs(form);
      page.value = payload;
      filter();
    };

    const onShowLimit = (current, pageSize) => {
      const firstRecord = (form.page - 1) * form.limit
      const { page, limit } = toRefs(form);
      page.value = Math.floor(firstRecord / pageSize) + 1
      limit.value = pageSize;
      filter();
    };

    return {
      form,
      filter,
      debounce: createDebounce(),
      onChange,
      onShowLimit
    };
  },
});
</script>

<style scoped>
.search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
</style>
