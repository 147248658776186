import axios from "../../axios";
import { Commit, Dispatch } from 'vuex'

const baseURL = "/adjustment_categories";

export default ({
  namespaced: true,
  state : {
    adjustment_categories: [],
    status: 'loading'
  },
  actions : {
    async index({commit}: {commit: Commit}, payload={}){
      commit('SET_STATUS', 'loading')
      const { data } = await axios.get(`${baseURL}`, {params: {...payload}});
      commit('SET_CATEGORIES', data)
      commit('SET_STATUS', 'completed')
      return data
    },

    async store({dispatch}: {dispatch: Dispatch}, payload={}){
      const { data } = await axios.post(`${baseURL}`, payload);
      dispatch('index')
      return data
    },

    async update({dispatch}: {dispatch: Dispatch}, payload={id: ''}){
      const { data } = await axios.put(`${baseURL}/${payload.id}`, payload);
      dispatch('index')
      return data
    },

    async destroy({dispatch}: {dispatch: Dispatch}, id=""){
      const { data } = await axios.delete(`${baseURL}/${id}`);
      dispatch('index')
      return data
    },

    async show({commit}: {commit: Commit}, id=""){
      const { data } = await axios.get(`${baseURL}/${id}`);
      return data
    },

  },
  mutations:{
    SET_CATEGORIES(state:any, data: any){
      state.adjustment_categories = data.data;
    },
    SET_STATUS(state: any, payload: string){
      state.status = payload;
    },
  },
  getters:{
    adjustment_categories(state: any){
      return state.adjustment_categories;
    },
    status(state: any){
      return state.status;
    }
  }
});




