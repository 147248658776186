import { User } from '@/classes/user';
import { Commit, Dispatch } from 'vuex'

import axios from "../../axios"

const baseURL = "auth";


export default ({
    namespaced: true,
    state : {
        user: new User
    },
    actions: {
        setUser: ({commit}:{commit: Commit} , user: User) => commit('SET_AUTH_USER', user),

        async accountRecovery({dispatch}: {dispatch: Dispatch}, payload={email: ''}){
            const { data } = await axios.put(`${baseURL}/forgot-password`, payload);
            return data
        },

    },
    mutations: {
        SET_AUTH_USER(state: {user: User}, user: User ){
            state.user = user
        },
    },
    getters: {
        user(state: {user: User}){
            return state.user;
        },
        // permissions(state: {user: User}){
        //     return state.user.permissions
        // }
    }
})
