import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { style: {"margin-bottom":"15px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        class: "search",
        span: "24"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input_search, {
            placeholder: "search",
            style: {"width":"300px"}
          }),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('create')))
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_PlusOutlined)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.addText), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}