import { createStore } from "vuex" 

import Dashboard from './modules/dashboard'
import AuthUser from './modules/auth'
import Zone from './modules/zone';
import Retailer from './modules/retailer'
import Cluster from './modules/cluster'
import SubCluster from './modules/sub_cluster'
import District from './modules/district'
import Plant from './modules/plant'
import AuditTrailLogin from './modules/audit_trail_login'
import ExceptionLog from './modules/exception_log'
import TaxType from './modules/tax_type'
import Variance from './modules/variance'
import AdjustmentCategory from './modules/adjustment_category'
import InventoryLogs from './modules/inventory_logs'

import Role from './modules/role'
import User from './modules/user'
import Pump from './modules/pump'
import Tank from './modules/tank'
import SiteDetails from './modules/site_details'
import SiteSubCluster from './modules/site_sub_cluster'
import SiteProduct from './modules/site_product'
import RetailerSites from './modules/retailer_site'

import RetailerInventories from './modules/retailer_inventory'
import Inventories from './modules/inventory'
import InventoryAdjustment from "./modules/inventory_adjustment";

import ProductType from './modules/product_type'
import ProductCategory from './modules/product_category'
import Product from './modules/product'
import ProductCode from './modules/product_code'
import ProductVariance from './modules/product_variance'

import Option from './modules/option'

import PlipReport from './modules/plip_report'
import Announcement from './modules/announcement'

export default createStore({
    modules: {
        Dashboard,
        AuthUser,
        Zone,
        Retailer,
        Cluster,
        SubCluster,
        Role,
        User,
        ProductType,
        ProductCategory,
        District,
        Plant,
        Pump,
        Tank,
        Option,
        Product,
        AuditTrailLogin,
        ExceptionLog,
        SiteDetails,
        TaxType,
        RetailerSites,
        RetailerInventories,
        Inventories,
        ProductCode,
        Variance,
        AdjustmentCategory,
        ProductVariance,
        SiteSubCluster,
        SiteProduct,
        InventoryAdjustment,
        PlipReport,
        Announcement,
        InventoryLogs
    }
});
