import { Entity } from '@/interfaces/entity'
import { Permission } from './permission'

export class Role implements Entity {
  id: string
  name: string
  permissions: Permission[]
  view: string

  constructor (id = '', name = '', permissions = [], view = '') {
    this.id = id
    this.name = name
    this.permissions = permissions
    this.view = view
  }
}
