import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f10d722"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"background":"#ECECEC"} }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, { bordered: false }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createVNode(_component_a_divider, { style: {"margin-bottom":"8px","margin-top":"5px"} }),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    })
  ]))
}